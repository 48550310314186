@import "../../../variables.css";

.dropDownHandler {
    padding: 7px 10px;
    width: 137px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-28-line-height);
    color: var(--ark-black-color);
    background: var(--ark-neutral-white);
    box-shadow: none;
    text-transform: none;
    border: 2px solid #FCFCFC;
    border-radius: 10px;
    cursor: pointer;
}

.dropDownHandler:disabled {
    color: var(--ark-primary-asbestos-figma);
    background-color: var(--ark-gray-light3-color);
    border: 2px solid var(--ark-primary-asbestos-figma);
}

.dropDownHandler:disabled .dropdownArrow {
    color: var(--ark-primary-asbestos-figma);
}

.localeItem {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.localeItemImage {
    width: 28px;
    height: 20px;
    border: 1px solid #C4C4C4;
    border-radius: 2px;
    background-color: var(--ark-neutral-white);
}

.dropDownBlock {
    margin-top: 4px;
    position: absolute;
    overflow: hidden;
    width: 137px;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    background: var(--ark-neutral-white);
    border-radius: 10px;
}

.dropDownBlock.visible {
    max-height: 200px;
}

.dropDownItems {
    overflow-y: auto;
    max-height: 200px;
}

.dropDownItems .dropDownItem {
    padding-left: 12px;
    outline-offset: calc(var(--focus-state-outline-width) * -1);
    padding-bottom: 0;
    height: 40px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: rgba(35, 31, 32, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropDownItem:hover {
    background: #EFEFEF;
}

.dropdownArrow {
    vertical-align: middle;
    color: var(--ark-black-color);
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
}

.dropdownArrow.arrowUp {
    transform: rotate(0deg);
}

/* Scrollbar Styling */
.dropDownContainer ::-webkit-scrollbar {
    width: 8px;
}

.dropDownContainer ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.dropDownContainer ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #d9d9d9;
}
